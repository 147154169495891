import { Spin } from 'antd';
import React, { CSSProperties } from 'react';

const LoaderOverlay = ({ isLoading }:any) => {
  if (!isLoading) return null;

  const overlayStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999, // Ensure it's on top of other elements
  };

  const messageStyle: CSSProperties = {
    color: '#fff',
    fontSize: '1.5rem',
    textAlign: 'center',
  };

  return (
    <div style={overlayStyle}>
    
      <div style={messageStyle}>
      {'Updating...'}
      </div>
    </div>
  );
};

export default LoaderOverlay;
