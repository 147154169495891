import { Carousel, Col, Dropdown, Flex, Menu, Row, Select, Slider, SliderSingleProps, Switch, Tooltip } from 'antd'
import SingleCardStoryBoard from './singleCardBoard'
import './sectionStoryBoard.less'
import { useGetVoices } from '@src/api/elevenLabs/getVoices'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { BiMusic } from 'react-icons/bi'
import { FaRegClosedCaptioning } from 'react-icons/fa'
import { categorisedMusicList, whisperAiVoiceList } from '@src/data/music'
import { aiVoiceDropDown, generateCategoryMusicOptions } from '@src/features/rightSidebar/audioPanel'
import ChooseFontTemplatesRow from '@src/features/rightSidebar/captions/rows/fontStyleRow'
import { FONT_TEMPLATE } from '@src/data/captions'
import { formatDuration } from '@src/shared/utils/convertSecs'
import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { useGlobalContext } from '@src/context/globalContext'
import { generateSubtitles } from '@src/shared/utils/captions'
import FontPickerRow from '@src/features/rightSidebar/captions/rows/fontPickerRow'
import { Label } from '@src/features/rightSidebar/captions/captionsPanel'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { generateUniqueId } from '@src/shared/utils/core'

const itemsPerPage = 3
const SectionStoryBoard = ({ store }: any) => {
  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value: any) => `${value}%`
  const {
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    ScriptDetails: [scriptDetails, setScriptDetails],
    StoreUpdatedInBasic: [storeUpdatedInBasic, setStoreUpdatedInBasic],
    ShowAdvancedEditor: [, setShowAdvancedEditor],
    BasicEditorSyncAfterCrud: [basicEditorSyncAfterCrud, setBasicEditorSyncAfterCrud],
  } = useGlobalContext()

  useEffect(() => {
    if (scriptDetails?.data) {
      console.log(scriptDetails.data)
    }
  }, [scriptDetails])
  const { data: aiVoice, isLoading, isSuccess } = useGetVoices()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPlayingTrack, setCurrentPlayingTrack] = useState<string | null>(null)
  const [backgroundVolume, setBackgroundVolume] = useState(
    (scriptDetails?.backgroundMusic?.backgroundVolume ?? 0.1) * 100
  )

  const totalItems = scriptDetails?.data?.length
  const scrollSmoothly = (direction: 'next' | 'prev') => {
    const offset = direction === 'next' ? 1 : -1
    const maxIndex = totalItems - itemsPerPage + 1
    const newIndex = currentIndex + offset
    if (totalItems < 4) {
      return
    }
    // Set the new index while keeping it within bounds [0, maxIndex]
    setCurrentIndex(Math.max(0, Math.min(newIndex, maxIndex)))
  }
  const audioRef = useRef<HTMLAudioElement>(null)
  const [selectedVoiceName, setSelectedVoiceName] = useState(`${scriptDetails?.backgroundMusic?.name || 'Custom'}`)

  const [fontTemplate, setFontTemplate] = useState<any>(
    store.pages[0].custom?.template
      ? store.pages[0].custom?.template
      : { ...FONT_TEMPLATE[0], position: store.height - store.height * 0.2 }
  )

  const [wordsPerLine, setWordsPerLine] = useState(
    store.pages[0].custom?.template ? store.pages[0].custom?.template.wordsPerLine : FONT_TEMPLATE[0].wordsPerLine
  )

  const [yAxisPosition, setYAxisPosition] = useState(store.height - store.height * 0.2)

  const [selectedFont, setSelectedFont] = useState(
    store.pages[0].custom?.template?.font ? store.pages[0].custom?.template.font : FONT_TEMPLATE[0].font
  )

  const [fontSize, setFontSize] = useState(
    store.pages[0].custom?.template ? store.pages[0].custom?.template.fontSize : FONT_TEMPLATE[0].fontSize
  )

  const setFontTemplates = (name: any) => {
    const template = FONT_TEMPLATE.find((template) => template.name === name)
    setFontTemplate(template)
  }

  useEffect(() => {
    if (store.pages[0].cutom?.template?.name !== fontTemplate.name) {
      generateSubtitles(
        undefined,
        undefined,
        undefined,
        store,
        wordsPerLine,
        fontTemplate,
        false, // show subtitle is false here
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      )
      setFontSize(fontTemplate.fontSize)
      setWordsPerLine(fontTemplate.wordsPerLine)
      setSelectedFont(fontTemplate.font)
    }
  }, [fontTemplate])

  const updateFont = (font: string) => {
    setSelectedFont(font)
    generateSubtitles(
      font,
      wordsPerLine,
      fontSize,
      store,
      wordsPerLine,
      fontTemplate,
      false,
      // showSubtitles,
      yAxisPosition,
      setYAxisPosition,
      setScriptDetails
    )
  }

  const updateFontSize = (fontSize: number) => {
    setFontSize(fontSize)
    generateSubtitles(
      selectedFont,
      wordsPerLine,
      fontSize,
      store,
      wordsPerLine,
      fontTemplate,
      false,
      // showSubtitles,
      yAxisPosition,
      setYAxisPosition,
      setScriptDetails
    )
  }

  const handlePlayAudio = (previewUrl: string) => {
    if (audioRef.current) {
      audioRef.current.src = previewUrl

      audioRef.current.addEventListener('loadedmetadata', () => {
        // Log duration
        console.log('Duration:', formatDuration(Math.floor(audioRef?.current?.duration || 1)))
      })
      audioRef.current.play().catch((e) => console.error('Playback was interrupted:', e))
      setCurrentPlayingTrack(previewUrl)
    }
  }

  // Pause audio handler
  const handlePauseAudio = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause()
      setCurrentPlayingTrack(null)
    }
  }

  const togglePlayPause = (value: string) => {
    const [industry, musicUrl, name] = value.split('@@@')

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    } else {
      handlePlayAudio(musicUrl)
    }
  }

  const categories = Object.keys(categorisedMusicList)
  const options: any = {}
  generateCategoryMusicOptions(
    categories,
    categorisedMusicList,
    currentPlayingTrack as string,
    togglePlayPause,
    options
  )

  const optionValues = Object.keys(options).map((option: any) => {
    return { label: option, options: options[option] }
  })

  const onBackgroundVolumeChange = async (newValue: number) => {
    setBackgroundVolume(newValue as number)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript: any = { ...prevScript }

      return {
        ...updatedScript,
        backgroundMusic: {
          ...updatedScript.backgroundMusic,
          backgroundVolume: newValue / 100,
        },
      }
    })
  }

  const handleBackgroundSound = (e: any) => {
    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }
      return { ...updatedScript, backgroundSound: e }
    })
  }

  const handleBackgroundMusicChange = async (value: string) => {
    const [industryName, musicUrl, name] = value.split('@@@')
    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    }

    // setSelectedIndustryName(industryName);
    // setSelectedMusicUrl(musicUrl);
    // setSelectedMusicName(name);
    setSelectedVoiceName(name)

    const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    const backgroundScript = {
      url: musicUrl,
      duration: backgroundAudioDuration,
      industry: industryName,
      name: name,
      backgroundVolume: (backgroundVolume ?? 0.1) * 100,
      // backgroundVolume: backgroundVolume / 100,
    }
    setBackgroundMusic(backgroundScript)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, backgroundMusic: backgroundScript }
    })
  }

  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const syncBasicEditor = async () => {
    //Temporary Ninja technique :-)
    setShowAdvancedEditor(true)
    setBasicEditorSyncAfterCrud(true)
    // Delay for 1 second
    await delay(1000)
    setShowAdvancedEditor(false)
    setBasicEditorSyncAfterCrud(false)
  }

  const insertScene = async () => {
    const tempScriptIndex = scriptDetails.data.length - 1
    // make one copy of the current section
    const copyCurrentSection = scriptDetails.data[tempScriptIndex]

    //Get all the pages with the same scriptIndex
    const pages = store.toJSON().pages
    const totalCurrentIndexPages = pages
      .filter((page: any) => page?.custom?.scriptIndex === tempScriptIndex)
      .map((page: any) => page.id)
    pages.map((page: any) => {
      if (page.custom.scriptIndex > tempScriptIndex) {
        const newScriptIndex = page.custom.scriptIndex + 1
        store.getElementById(page.id).set({ custom: { ...page.custom, scriptIndex: newScriptIndex } })
      }
    })

    // Clone all the pages with the same scriptIndex and set the new scriptIndex(increment)
    totalCurrentIndexPages.forEach((pageId: string) => {
      const ClonedPageId: any = generateUniqueId()
      store.getElementById(pageId).clone({ id: ClonedPageId })
      const clonedPage = store.getElementById(ClonedPageId)
      clonedPage.set({ custom: { scriptIndex: tempScriptIndex + 1 } })
    })

    setScriptDetails((prevScript: { data: any[] }) => {
      const updatedScript = { ...prevScript }
      const updatedData = [...prevScript.data]

      // Create a copy of the current section and set it as the new scene
      const newScene = { ...copyCurrentSection, adBlockType: 'Custom' }
      updatedData.splice(tempScriptIndex + 1, 0, newScene)

      return { ...updatedScript, data: updatedData }
    })
    setStoreUpdatedInBasic(true)
    syncBasicEditor()
  }

  const bgMusicMenu = (
    <Flex
      style={{
        minWidth: '280px',
        backgroundColor: 'white',
        paddingInline: '8px',
        paddingBlock: '4px',
        borderRadius: '8px',
      }}
      vertical
      gap={5}
    >
      <div>
        <Label>Select Background Music</Label>
        <Select
          style={{ minWidth: '240px', width: '100%' }}
          placeholder='Select Background Music'
          optionFilterProp='children'
          onChange={handleBackgroundMusicChange}
          value={selectedVoiceName}
          defaultValue={selectedVoiceName}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <audio ref={audioRef} src={''} style={{ display: 'none' }} />
            </div>
          )}
          options={optionValues}
        />
      </div>
      <div>
        <Label>Background Music Volume</Label>
        <Slider
          style={{
            width: '80%',
          }}
          min={1}
          max={100}
          onChange={(value) => setBackgroundVolume(value)}
          onChangeComplete={onBackgroundVolumeChange}
          tooltip={{ formatter }}
          defaultValue={backgroundVolume}
          value={typeof backgroundVolume === 'number' ? backgroundVolume : 0}
        />
      </div>
      <div
        style={{
          marginTop: '-8px',
          display: 'flex',
          gap: '12px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Label>Background Music</Label>
        <Switch
          defaultChecked={scriptDetails?.backgroundSound ?? true}
          value={scriptDetails?.backgroundSound}
          onChange={(e) => handleBackgroundSound(e)}
          style={{ width: 'fit-content' }}
        />
      </div>
    </Flex>
  )

  const captionsMenu = (
    <Flex
      style={{
        minWidth: '280px',
        backgroundColor: 'white',
        paddingInline: '8px',
        paddingBlock: '4px',
        borderRadius: '8px',
      }}
      vertical
      gap='8px'
    >
      <div>
        <Label>Font</Label>
        <FontPickerRow
          defaultFont={selectedFont}
          onFontChange={updateFont}
          fontSize={fontSize}
          onFontSizeChange={updateFontSize}
        />
      </div>
      <div>
        <Label>Caption Style</Label>
        <ChooseFontTemplatesRow templates={FONT_TEMPLATE} onSelect={setFontTemplates} />
      </div>
    </Flex>
  )

  return (
    <>
      <div style={{ position: 'relative', marginBlock: '8px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            gap: '20px',
            position: 'absolute',
            width: '36vw',
            zIndex: '2',
          }}
        >
          <div
            onClick={() => {
              if (currentIndex !== 0) scrollSmoothly('prev')
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: currentIndex === 0 ? 'default' : 'pointer',
            }}
          >
            <LeftOutlined
              style={{
                fontSize: '18px',
                color: currentIndex === 0 ? '#A0A0A0' : 'var(--color-primary-700)',
              }}
            />
            <p
              style={{
                color: currentIndex === 0 ? '#A0A0A0' : 'var(--color-primary-700)',
                cursor: currentIndex === 0 ? 'default' : 'pointer',
              }}
            >
              Prev
            </p>
          </div>

          <div
            onClick={() => {
              if (totalItems - currentIndex > 3) scrollSmoothly('next')
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: totalItems - currentIndex < 4 ? 'default' : 'pointer',
            }}
          >
            <RightOutlined
              style={{
                fontSize: '18px', // Customize icon size
                color: totalItems - currentIndex < 4 ? '#A0A0A0' : 'var(--color-primary-700)',
              }}
            />
            <p
              style={{
                color: totalItems - currentIndex < 4 ? '#A0A0A0' : 'var(--color-primary-700)',
                cursor: totalItems - currentIndex < 4 ? 'default' : 'pointer',
              }}
            >
              Next
            </p>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', height: 'fit-content', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', marginTop: '6vh' }}>
          <div
            style={{
              display: 'flex',
              transform: `translateX(-${currentIndex * (100 / totalItems)}%)`,
              transition: 'transform 0.5s ease',
              // alignItems: 'center',
              gap: '8px',
              overflowX: 'hidden',
            }}
            // className="custom-scrollbar"
          >
            {scriptDetails?.data?.map((script: any, index: number) => (
              <SingleCardStoryBoard
                key={index}
                store={store}
                lastCard={scriptDetails?.data?.length === index + 1}
                script={script}
                scriptIndex={index}
                aiVoice={aiVoice?.voices || []}
              />
            ))}
            <Flex align='center' style={{ display: 'flex', minHeight: '66vh', height: '100%', alignItems: 'center' }}>
              <div style={{ cursor: 'pointer' }} onClick={insertScene}>
                <IoMdAddCircleOutline size={50} color='var(--color-primary-700)' />
              </div>
            </Flex>
          </div>
        </div>
        <div style={{ width: '80vw', display: 'flex', justifyContent: 'center', marginTop: '12px', gap: '16px' }}>
          <Tooltip title='Edit Captions'>
            <Dropdown overlay={captionsMenu} trigger={['click']}>
              <div style={{ cursor: 'pointer' }}>
                <FaRegClosedCaptioning size={28} color='var(--color-primary-700)' />
              </div>
            </Dropdown>
          </Tooltip>
          <Tooltip title='Change Background Music'>
            <Dropdown overlay={bgMusicMenu} trigger={['click']}>
              <div style={{ cursor: 'pointer' }}>
                <BiMusic size={28} color='var(--color-primary-700)' />
              </div>
            </Dropdown>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default observer(SectionStoryBoard)
