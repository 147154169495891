import { Player, Thumbnail } from '@remotion/player'
import React, { useCallback, useEffect } from 'react'
import { MyCompositionHtml } from './myCompositionHtml'
import RenderPosterComponent from './renderPoster'
import { renderStill } from '@remotion/renderer'
// import { MyComp } from '@src/remotionRoot'

type gifProps = {
  htmlData: any
  duration: number
  jsonData: any
  transitionDurationInFrames: number
  transitionAnimationProp: any
  isDownload: boolean
  scriptDetails: any
  burstImagesTransitionAnimationArray: any
  videoStyle: string,
  aiAvatar: boolean,
  widthVW?: number,
  heightVH?: number,
}

// Assuming you want to use the Remotion Player for previewing the video
export const VideoPlayer = ({
  htmlData,
  duration,
  jsonData,
  transitionDurationInFrames,
  transitionAnimationProp,
  isDownload,
  scriptDetails,
  burstImagesTransitionAnimationArray,
  videoStyle,
  aiAvatar,
  widthVW = 0.3,
  heightVH = 0.65
}: gifProps) => {

  // const videoPlayerWidth = Math.round((isDownload ? jsonData.width : Math.round(jsonData.width / 1.7)))
  // const videoPlayerHeight = Math.round((isDownload ? jsonData.height : Math.round(jsonData.height / 1.7)))

  const maxVideoWidth = window.innerWidth * widthVW;
  const maxVideoHeight = window.innerHeight * heightVH;

  const videoPlayerWidth = isDownload ? jsonData.width : Math.round(jsonData.width / 1);
  const videoPlayerHeight = isDownload ? jsonData.height : Math.round(jsonData.height / 1);

  // Calculate scaled dimensions
  const aspectRatio = videoPlayerWidth / videoPlayerHeight;
  let scaledWidth = videoPlayerWidth;
  let scaledHeight = videoPlayerHeight;

  if (scaledWidth > maxVideoWidth) {
    scaledWidth = maxVideoWidth;
    scaledHeight = maxVideoWidth / aspectRatio;
  }

  if (scaledHeight > maxVideoHeight) {
    scaledHeight = maxVideoHeight;
    scaledWidth = maxVideoHeight * aspectRatio;
  }

  const memoizedInputProps = React.useMemo(
    () => ({
      htmlData,
      jsonData: jsonData,
      duration,
      width: videoPlayerWidth, // Use the scaled values but keep the names as expected
      height: videoPlayerHeight,
      videoPlayerWidth,
      videoPlayerHeight,
      transitionDurationInFrames,
      transitionAnimationProp,
      isDownload,
      scriptDetails,
      burstImagesTransitionAnimationArray,
      videoStyle,
      aiAvatar
    }),
    [
      htmlData,
      jsonData,
      scriptDetails,
      duration,
      videoPlayerWidth,
      videoPlayerHeight,
      transitionDurationInFrames,
      transitionAnimationProp,
      isDownload,
      burstImagesTransitionAnimationArray,
      videoStyle,
      aiAvatar
    ]
  )

  const renderPoster = useCallback(
    (props: any) => <RenderPosterComponent {...props} thumbnailImage={scriptDetails.thumbnailImage || localStorage.getItem('thumbnailImage')} thumbnailMessage={scriptDetails.data[0].voiceOver} />,
    []
  );

  // pass the memoizedInputProps in the inputProps, so it can be passed to MyCompositionHtml component

  //   useEffect(()=>{
  //       async function renderThumbnail(){
  //         await renderStill({
  //           renderPoster,
  //           // RenderPosterComponent,
  //           serveUrl: 'videoRender',
  //           output: "/tmp/still.png",
  //           inputProps: {
  //             thumbnailImag: scriptDetails.thumbnailImage,
  //             thumbnailMessage: scriptDetails.data[0].voiceOver
  //           },
  //         });
  // }
  //   },[])

  return (

    <Player
      fps={30}
      durationInFrames={duration <= 0 ? 400 : duration}
      component={MyCompositionHtml}
      autoPlay={false}
      inputProps={memoizedInputProps}
      controls={true} // Show player controls
      // compositionWidth={Math.round(videoPlayerWidth/1.7)} // Add composition width here
      compositionWidth={videoPlayerWidth}
      compositionHeight={videoPlayerHeight}
      // compositionHeight={Math.round(videoPlayerHeight/1.7)} // Add composition height here
      numberOfSharedAudioTags={10}
      // renderPoster={renderPoster}
      // showPosterWhenUnplayed
      style={{
        // width: `${Math.round(videoPlayerWidth / 1.7)}px`,
        // height: `${Math.round(videoPlayerHeight / 1.7)}px`,
        width: `${scaledWidth}px`,
        height: `${scaledHeight}px`,
      }}
    />

  )
}
