// import { generateAiAvatarVideo } from "./VideoGeneration/helperFunctions/tavusApi";

// export const getElementIdJsonPage = async(jsonPages: any, scriptDetails:any, userDetails:any, setAvatarTasks:any) => {
//     console.log(jsonPages)
//   //--Map plotno json to get all the video elements related to avatar
//   const elementIds: any = [];
//   jsonPages?.pages?.map((page: any) => {
//     page?.children?.map((child: any) => {
//       if (child?.custom?.aiAvatar && child.type == "video") {
//         const obj = {
//           elementId: child.id,
//           scriptIndex: child.custom?.scriptIndex,
//         };
//         elementIds.push(obj);

//       }
//     });
//   });
//   await generateAvatarFromAudio(scriptDetails, elementIds, userDetails, setAvatarTasks)
//   return elementIds;
// };

// export const generateAvatarFromAudio=async (scriptDetails:any, elementIds:any, userDetails:any, setAvatarTasks:any )=>{
//     // scriptDetails.data[]
//     elementIds.forEach(async(element:any)=>{
//         scriptDetails.data[element.scriptIndex]
//         // const voiceOverText=scriptDetails.data[element.scriptIndex].voiceOver
//         // const voiceOverAudio=scriptDetails.data[element.scriptIndex]?.url

//         const avatarResponse = await generateAiAvatarVideo(
//             scriptDetails.data[element.scriptIndex]?.url || "",
//           userDetails?.purpose?.avatarReplicaId,
//           "",
//           "",
//           scriptDetails.data[element.scriptIndex].voiceOver,
//           scriptDetails.data[element.scriptIndex]?.aiAvatarProps?.scriptAvatar
//         );

//         const newTask={
//             elementId:element.elementId,
//             taskStatus: avatarResponse?.status,
//             outPutUrl: "",
//             videoId: element.elementId,
//             scriptIndex:element.scriptIndex,
//             lessThanThreeSec: false,
//         }

//         setAvatarTasks((prev:any)=>[...prev, newTask])
//     })
// }

import { generateAiAvatarVideo } from "./VideoGeneration/helperFunctions/tavusApi";

export const getElementIdJsonPage = async (
  jsonPages: any,
  scriptDetails: any,
  userDetails: any,
  setAvatarTasks: any,
  setAvatarTriggered: any
): Promise<any[]> => {
  if (!jsonPages?.pages) {
    console.error("Invalid jsonPages data");
    return [];
  }

  // Collect all video elements related to the avatar
  const elementIds: { elementId: string; scriptIndex: number }[] = [];
  jsonPages.pages.forEach((page: any) => {
    page?.children?.forEach((child: any) => {
      if (child?.custom?.aiAvatar && child.type === "video") {
        elementIds.push({
          elementId: child.id,
          scriptIndex: child.custom?.scriptIndex,
        });
      }
    });
  });

  await generateAvatarFromAudio(
    scriptDetails,
    elementIds,
    userDetails,
    setAvatarTasks
  );
  setAvatarTriggered(true);
  return elementIds;
};

export const generateAvatarFromAudio = async (
  scriptDetails: any,
  elementIds: { elementId: string; scriptIndex: number }[],
  userDetails: any,
  setAvatarTasks: any
): Promise<void> => {
  if (!Array.isArray(scriptDetails?.data)) {
    console.error("Invalid scriptDetails data");
    return;
  }

  for (const element of elementIds) {
    const scriptData = scriptDetails.data[element.scriptIndex];
    if (!scriptData) {
      console.warn(
        `No script data found for scriptIndex: ${element.scriptIndex}`
      );
      continue;
    }

    try {
      const avatarResponse = await generateAiAvatarVideo(
        scriptData?.url || "",
        userDetails?.purpose?.avatarReplicaId || "",
        "",
        "",
        scriptData?.voiceOver || "",
        scriptData?.aiAvatarProps?.scriptAvatar || {}
      );
      const newTask = {
        elementId: element.elementId,
        taskStatus: avatarResponse?.status || "unknown",
        outPutUrl: "",
        videoId: avatarResponse?.video_id,
        scriptIndex: element.scriptIndex,
        lessThanThreeSec: false,
      };

      setAvatarTasks((prev: any[]) => [...prev, newTask]);
    } catch (error) {
      console.error(
        `Error generating avatar for elementId: ${element.elementId}`,
        error
      );
    }
  }
};

export const getElementIdJsonPageTavusPayload = async (
  jsonPages: any,
  scriptDetails: any,
  userDetails: any
): Promise<any[]> => {
  if (!jsonPages?.pages) {
    console.error("Invalid jsonPages data");
    return [];
  }
  
  // Collect all video elements related to the avatar
  const elementIds: {
    element_id: string;
    // scriptIndex: number;
    replica_id:string,
    script: string;
    audio_url: string;
    background_url: string;
    background_source_url: string;
    callback_url: string;
    video_name: string;
    transparent_background: boolean;
    fast: boolean;
    background_scroll: boolean;
    background_scroll_type: string;
    background_scroll_depth: number;
    background_scroll_return: boolean;
   
  }[] = [];
  jsonPages.pages.forEach((page: any) => {
    page?.children?.forEach((child: any) => {
      if (child?.custom?.aiAvatar && child.type === "video") {
        const scriptData = scriptDetails.data[child.custom?.scriptIndex]
    
        elementIds.push({
          
          element_id: child.id,
          // scriptIndex: child.custom?.scriptIndex,
          // script:(scriptData?.aiAvatarProps?.scriptAvatar || !scriptData?.url)?scriptData?.voiceOver:'',
          script:'',
          audio_url:scriptData?.url || "",
          background_url:'',
          background_source_url:'',
          callback_url:'',
          video_name:'',
          transparent_background:child?.custom?.bg ? child?.custom?.bg : false,
          fast:true,
          background_scroll: false,
          background_scroll_type: 'human',
          background_scroll_depth: 0,
          background_scroll_return: false,
          replica_id:userDetails?.purpose?.avatarReplicaId
        });
      }
    });
  });

  return elementIds;
};
