import { Alert, Modal } from "antd";
import { QuickAdsLogoBlack } from "../../../src/assets";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useState } from "react";
import { updateProject } from "../utils/core";
import { useGlobalContext } from "@src/context/globalContext";
import LoaderOverlay from "./loaderOverlayTwo";

export const PageLayout = ({
  children,
  heading,
  domainData,
  store,
  open,
  setOpen,
  bulkCreatePropsArray,
  setBulkCreatePropsArray,
  previewOpen,
  setPreviewOpen,
  setProgressBarPercentage,
}: {
  children: React.ReactNode;
  heading: string;
  domainData: any;
  store: any;
  open: any;
  setOpen: any;
  bulkCreatePropsArray: any;
  setBulkCreatePropsArray: any;
  previewOpen: any;
  setPreviewOpen: any;
  setProgressBarPercentage: any;
}) => {
  const {
    UserDetail: [userDetails],
    ScriptDetails: [scriptDetails],
    PreviewLoader: [isPreviewLoading],
    BasicEditorSyncAfterCrud:[basicEditorSyncAfterCrud]
  } = useGlobalContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await updateProject(userDetails, store, scriptDetails);
    const url = `https://${domainData?.domain_name}/`;
    window.location.href = url;
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        margin: "0px",
      }}
    >
      <LoaderOverlay isLoading={basicEditorSyncAfterCrud} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderTopRightRadius: "25px",
          paddingLeft: "30px",
          paddingTop: "20px",
          paddingBottom: "20px",
          alignItems: "center",
          height: "7vh",
          justifyContent: "space-between", // This aligns the content with space between them
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowLeftOutlined
            style={{ fontSize: "20px" }}
            onClick={() => showModal()}
          />
          <img
            src={
              domainData?.premium_domain_name?.includes("quickads")
                ? QuickAdsLogoBlack
                : domainData?.logo_url
            }
            width={
              domainData?.premium_domain_name?.includes("quickads") ? 110 : ""
            }
            alt="Logo"
            style={{ marginLeft: "20px", maxHeight: "46px", cursor: "pointer" }}
            onClick={() => {
              showModal();
            }}
          />
        </div>
        <Alert
          message="Lip syncing with audio, watermark-free content, and transparent avatars are available in the final download"
          type="info"
          style={{
            fontSize: "12px",
            marginRight: "20px", // Added margin to the right for spacing from the border
          }}
        />

        <div></div>
      </div>
      {isPreviewLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 30px",
            paddingLeft: "208px",
          }}

        >
          <div> </div>
          <Alert
            message="Once rendering is completed, the download will start automatically, and the preview will be enabled again."
            type="info"
            style={{
              fontSize: "12px",
              marginRight: "20px", // Added margin to the right for spacing from the border
            }}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "auto",
        }}
      >
        {children}
      </div>

      <Modal
        title="Go Back"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save and Go Back to Home"
        okButtonProps={{
          style: { backgroundColor: "var(--color-primary-500)" }, // Change the background and border color
        }}
      >
        <p>Are you sure you want to go back to the home page?</p>
      </Modal>
    </div>
  );
};
